// import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../auth/users/userSlice';
import invoiceReducer from '../store/reducers/invoiceReducer';
import estimateReducer from '../store/reducers/estimateReducer';
import calendarReducer from '../store/reducers/calendarReducer';
import profileReducer from '../store/reducers/profileReducer';
import { createStore, combineReducers, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import balanceReducer from './reducers/balanceReducer';

const rootReducer = combineReducers({
  user: userReducer,
  invoices: invoiceReducer,
  estimates:estimateReducer,
  events: calendarReducer,
  profiles: profileReducer,
  expenses: balanceReducer
})
export const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

